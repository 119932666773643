import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

/**
 * An extensible, general-purpose SEO component for Gatsby sites. Queries your siteMetadata, accepts overriding props, and injects the metadata into your page's head.
 *
 * @requires react
 * @requires gatsby
 * @requires prop-types
 * @requires react-helmet
 *
 * Other Requirements: At a minimum, your gatsby-config.js file should include the following siteMetadata:
 * - title
 * - description
 * - author
 * - siteUrl
 * - twitterUsername
 * - lang
 *
 * If you do not want to include all of these in your siteMetadata, you must remove them from the graphql staticQuery in this component. No other changes should need to be made.
 *
 * All props are optional, but this component will perform best when supplied with more page-specific data.
 *
 * @param props.title - String. Page title. Should be descriptive and unique within the site. Should be long enough to be descriptive of the page content, but not longer than 55-60 characters.
 * @param props.description - String. Page description to display in Google search results. Should ideally be 150-170 characters.
 * @param props.author - String. Page/article author.
 * @param props.twitterUsername - String. Twitter username for the current page/article.
 * @param props.lang - String. Page language. Falls back to 'en'.
 * @param props.image - Object {src: string, width: number, height: number}. Header image. Displayed in social media cards.
 * @param props.pathname - String. Relative pathname to the page. Used in generating canonical links.
 * @param props.article - Boolean. Whether the page is an article (e.g., blog post).
 * @param props.keywords - Array of strings. Keywords for the page/article.
 * @param props.meta - Array of objects: {name, content}. Any extra metadata for the page. Directly passed to React Helmet.
 */
const GatsbySEO = ({
  title,
  description,
  author,
  twitterUsername,
  lang,
  image: metaImage,
  pathname,
  article,
  keywords,
  meta,
}) => {
  // query to get site metadata
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
          lang
        }
      }
    }
  `);

  // compare props to query results: favor props, then fall back to siteMetadata
  const seo = {
    title: title || siteMetadata.title,
    description: description || siteMetadata.description,
    lang: lang || siteMetadata.lang || 'en', // fall back to english
    image:
      metaImage && metaImage.src
        ? {
            src: `${siteMetadata.siteUrl}${metaImage.src}`,
            height: metaImage.height,
            width: metaImage.width,
          }
        : null,
    canonical: pathname ? `${siteMetadata.siteUrl}${pathname}` : null,
    keywords,
    twitterUsername: twitterUsername || siteMetadata.twitterUsername,
    author: author || siteMetadata.author,
  };

  // attributes to be injected into the html tag
  const htmlContent = { lang: seo.lang };

  // metadata
  const metaContent = [
    // metadata that can always be set
    { property: 'og:title', content: seo.title },
    { property: 'og:type', content: article ? 'article' : 'website' },
    { name: 'twitter:title', content: seo.title },
  ]
    .concat(
      seo.description
        ? [
            // description
            { name: 'description', content: seo.description },
            { property: 'og:description', content: seo.description },
            { name: 'twitter:description', content: seo.description },
          ]
        : []
    )
    .concat(
      seo.keywords.length
        ? [
            // keywords
            { name: 'keywords', content: seo.keywords.join(',') },
          ]
        : []
    )
    .concat(
      seo.image
        ? [
            // image
            { name: 'image', content: seo.image.src },
            { property: 'og:image', content: seo.image.src },
            { property: 'og:image:width', content: seo.image.width },
            {
              property: 'og:image:height',
              content: seo.image.height,
            },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:image', content: seo.image.src },
          ]
        : [
            // fallback twitter card type, in case of no image
            { name: 'twitter:card', content: 'summary' },
          ]
    )
    .concat(
      seo.twitterUsername
        ? [
            // twitter username
            {
              name: 'twitter:creator',
              content: seo.twitterUsername,
            },
          ]
        : []
    )
    .concat(seo.author ? [{ name: 'author', content: seo.author }] : [])
    .concat(
      seo.canonical
        ? [
            // canonical link
            {
              property: 'og:url',
              content: seo.canonical,
            },
          ]
        : []
    )
    .concat(meta); // concatenate any extra meta passed directly to the component

  // page's title
  let pageTitle = seo.title;

  // if there is a canonical link, render it
  const pageLink = seo.canonical
    ? [{ rel: 'canonical', href: seo.canonical }]
    : [];

  return (
    <Helmet
      htmlAttributes={htmlContent}
      title={pageTitle}
      link={pageLink}
      meta={metaContent}
    />
  );
};

GatsbySEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  twitterUsername: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  keywords: PropTypes.arrayOf(PropTypes.string),
  article: PropTypes.bool,
  pathname: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

GatsbySEO.defaultProps = {
  meta: [],
  keywords: [],
};

export default GatsbySEO;
