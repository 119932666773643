import React from 'react';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-gray-900 text-gray-50 p-14 text-center tracking-[0.1em] flex justify-center text-base md:text-sm">
      <p className="text-left md:text-center">
        © {year}, Thomas Michael Rutter. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
