import React from 'react';

/**
 * Renders an internal link that takes the user to the element with id="main". Hidden from sighted users when not focused. Intended to allow keyboard users to skip the nav links and go straight to the page content.
 */
const SkipToMain = () => {
  return (
    <a
      href="#main"
      className="text-lg py-3 px-6 bg-gray-50 text-gray-900 border-2 border-gray-900 fixed top-0 left-0 z-[9999] transition-all focus:outline-none -translate-x-full focus:translate-x-0"
    >
      Skip to main content
    </a>
  );
};

export default SkipToMain;
