import React from 'react';

import Nav from './Nav';
import Footer from './Footer';
import SkipToMain from './SkipToMain';

const Layout = ({ children }) => {
  return (
    <div className="font-light bg-gray-50 text-gray-900 overflow-x-hidden">
      <SkipToMain />
      <Nav />
      <main id="main" className="min-h-[70vh] px-8 py-10 md:py-16 lg:py-20">
        <div className="container mx-auto">
          <div className="max-w-screen-lg mx-auto">{children}</div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
