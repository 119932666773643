import React from 'react';

const Content = ({ children }) => {
  return (
    <div className="max-w-screen-md tracking-wider leading-normal text-base md:text-lg md:mx-8">
      {children}
    </div>
  );
};

export default Content;
