import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ className, children }) => {
  return (
    <h1
      className={`font-heading uppercase text-4xl md:text-5xl lg:text-7xl mb-6 lg:mb-8 tracking-[0.1em] ${className}`}
    >
      {children}
    </h1>
  );
};

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Title.defaultProps = {
  className: '',
};

export default Title;
