import React from 'react';
import { Link } from 'gatsby';

const Nav = () => {
  return (
    <>
      <nav className="bg-headerSmall sm:bg-header bg-no-repeat bg-center bg-cover fixed top-0 left-0 right-0 md:relative">
        <div className="w-full h-full py-4 md:py-8 lg:py-14 px-8 bg-gray-900/70">
          <div className="container mx-auto uppercase text-gray-50 flex justify-between items-center h-full font-heading leading-none tracking-[0.1em]">
            <Link
              to="/"
              className="text-2xl lg:text-4xl relative outline-none after:absolute after:left-1/4 after:right-1/4   after:bottom-0 after:h-px after:bg-gray-50 after:scale-x-0 after:transition-all after:duration-200 hover:after:scale-x-100 focus:after:scale-x-100"
            >
              <span className="inline-block sm:hidden">TMR</span>
              <span className="hidden sm:inline-block">
                Thomas Michael Rutter
              </span>
            </Link>
          </div>
        </div>
      </nav>
      <div className="pt-[4.5rem] md:hidden" />
    </>
  );
};

export default Nav;
